.startable {
  display: grid;
  grid-template-columns: 1fr min-content;
  grid-template-rows: min-content min-content min-content;
  grid-template-areas:
    "startable-title  startable-title   startable-title"
    "startable-body   startable-body    startable-buttons"
    ".                .                 startable-buttons";

  padding: 1rem;

  border-radius: 4px;
  color: var(--text-color);
  box-shadow: 0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15);
}

.startable__title {
  grid-area: startable-title;

  padding-top: 0;
  padding-bottom: 0;
  line-height: inherit;
  font-size: 1.125rem;
  white-space: nowrap;

  overflow: hidden;
  text-overflow: ellipsis;
}

.startable__body {
  grid-area: startable-body;

  font-size: 0.875rem;
}

.startable__buttons {
  grid-area: startable-buttons;

  margin-top: 0.5rem;
  white-space: nowrap;
}
