// stylesheets that are required by the application.
// The styles provided here are not supposed to be overwritten by themes.
@import '~bootstrap/scss/bootstrap.scss';

:root {
  /* OVERALL */

  --background-color: #f9fafc;
  --text-color: #0e283b;
  --link-color: #12a6d7;

  --highlight-background-color: #ff0;
  --highlight-text-color: #0e283b;

  /* SIDEBAR */

  --sidebar-background-color: #0e283b;
  --sidebar-text-color: #fff;

  --sidebar-item-background-color: transparent;
  --sidebar-item-text-color: #fff;
  --sidebar-item-border-color: transparent;

  --sidebar-item-active-background-color: rgba(255,255,255,0.2);
  --sidebar-item-active-text-color: #fff;
  --sidebar-item-active-border-color: #00AED7;

  /* BUTTONS */

  --button-background-color: #00aed7;
  --button-border-color: #00aed7;
  --button-text-color: #fff;

  --button-hover-background-color: #fff;
  --button-hover-text-color: #00aed7;

  /* BOX */

  --box-background-color: #fff;
  --box-text-color: inherit;

  /* TASK LIST */

  --task-list-header-background-color: #F9FAFC;
  --task-list-header-text-color: #12a6d7;

  /* APP HEADER */

  --search-bar-input-background-color: #FFFFFF;
  --search-bar-input-border-color: #E3E3E3;
  --search-bar-input-text-color: #000000;

  --search-bar-input-focus-background-color: #FFFFFF;
  --search-bar-input-focus-border-color: #00aed7;
  --search-bar-input-focus-text-color: #000000;

  --search-bar-submit-text-color: #00AED7

  /* Diese Liste ist noch nicht vollständig und dient mehr der Illustration des Konzepts */
}



// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) { /* ... */ }

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) { /* ... */ }

// Large devices (desktops, 992px and up)
@media (min-width: 992px) { /* ... */ }

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) { /* ... */ }



html, body, #root, :root {
  width: 100%;
  height: 100%;
}

html {
  font-size: 16px;
}

a,
a:hover,
a:active {
  color: var(--link-color);
}

.app-layout {
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-columns: 1fr;
  grid-template-rows: min-content 1fr;
  grid-template-areas:
    "header"
    "content";

  background-color: var(--background-color, #fff);
  color: var(--text-color, #333);
  font-size: var(--font-size, 1rem);
}

@media (min-width: 768px) {
  .app-layout {
    grid-template-columns: 240px 1fr;
    grid-template-rows: min-content 1fr;
    grid-template-areas:
      "sidebar header"
      "sidebar content";
  }
}

.app-layout__app-sidebar {
  grid-area: sidebar;

  display: flex;
  flex-direction: column;
}

@import "app-sidebar.scss";

.app-layout__app-header {
  grid-area: header;
}

@import "app-header.scss";

.app-layout__content {
  grid-area: content;
}

@import "box.scss";

@import "startable.scss";

@import "startable-group.scss";

.app-layout__content {
  padding: 0 1rem;
}

@media (min-width: 576px) {
  .app-layout__content {
    padding: 0 2rem;
  }
}

.app-sticky-message {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 200;
  width: 100%;
}

@import "homepage-view.scss";

@import "task-view.scss";

@import "startable-list-view.scss";

.start-dialog-list {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-template-rows: repeat(auto-fit, minmax(0, max-content));
  grid-gap: 1.5rem;
}

@import "task-list.scss";

@import "correlation-tracker-view.scss";

@import "user-settings-view.scss";

@import "login-prompt-view.scss";

@import "start-dialog-view.scss";

/**
 * base component styling
 */

.flash-message__cancel-button {
  border: none;
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.75rem 1.25rem;
  font-size: 1.5rem;
  line-height: 1;
}

.flash-message__cancel-button:hover:not(:disabled) {
  background-color: transparent;
}

.flash-message__cancel-button:focus {
  box-shadow: none;
}

.icon {
  width: 1.25rem;
  height: 1.25rem;

  vertical-align: text-bottom;
}

.btn--default,
.btn--start-button,
.btn--task-continue {
  background-color: var(--button-background-color);
  border-color: var(--button-border-color);
  color: var(--button-text-color);

  &:disabled {
    opacity: 0.6;
    transition: none;
  }

  &.active,
  &:hover:not(:disabled) {
    background-color: var(--button-hover-background-color, var(--button-text-color));
    color: var(--button-hover-text-color, var(--button-background-color));
  }
}


/**
 * specific component styling
 */

.flash-message__cancel-button {
  opacity: 0.5;
  font-weight: bold;
}

.flash-message__cancel-button:hover {
  opacity: 0.8;
}

.back-to-homepage {
  grid-area: back-to-homepage;

  padding: 1rem 0;
}

.back-to-homepage__link {
  color: var(--bright-cerulean);
}

.text-highlight {
  background: var(--highlight-background-color);
  color: var(--highlight-text-color);
}

@import "hacks.scss"
