.box {
  margin-bottom: 2rem;

  background-color: var(--box-background-color);
  color: var(--box-text-color, var(--text-color));
  border-radius: 4px;
  box-shadow: 0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15);
}

.box__title {
  padding: 1rem 1.5rem;
  line-height: inherit;
  font-size: 1.125rem;
  white-space: nowrap;
}

.box__subtitle {
  padding: 1rem 1.5rem;
  line-height: inherit;
  font-size: 1rem;
  white-space: nowrap;
}

.box__body {
  padding: 1rem 1.5rem;
}

.box__footer {
  padding: 1rem 1.5rem;
}
