/* ********** */

/* ***** HACKS FOR 2.0 ALPHA VERSION BELOW ***** */

/* ********** */

.app-sidebar__logo img {
  display: block;
  margin-left: 15px;
  margin-top: 12px;
}

.app-sidebar__logo img {
  max-height: 18px;
}

.process-model {
  background: linear-gradient(36.64deg, #FFDC40 0%, #FF8356 100%);
  box-shadow: 0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15);
}

.btn--start-button {
  background-color: transparent;
  border-color: #fff;
  color: #fff;
}

.btn--start-button:disabled {
  opacity: 0.6;
  transition: none;
}

.btn--start-button.active,
.btn--start-button:hover:not(:disabled) {
  background-color: #fff;
  color: var(--text-color);
}

.startable {
  color: #fff;

  &:nth-child(1n) {
    background: linear-gradient(45.92deg, #12E7D9 0%, #5B78E8 100%);
  }

  &:nth-child(2n) {
    background: linear-gradient(36.64deg, #FFDC40 0%, #FF8356 100%);
  }

  &:nth-child(3n) {
    background: linear-gradient(48.52deg, #3AE591 0%, #3BB3B5 100%);
  }

  &:nth-child(4n) {
    background: linear-gradient(54.07deg, #F54B9F 0%, #FE7374 100%);
  }

  &:nth-child(5n) {
    background: linear-gradient(36.64deg, #9553FD 0%, #5D4BE3 100%);
  }

  &:nth-child(6n) {
    background: linear-gradient(36.64deg, #1FDB52 0%, #31F22E 100%);
  }

  &:nth-child(7n) {
    background: linear-gradient(36.64deg, #F94E3E 0%, #DB375A 100%);
  }

  &:nth-child(8n) {
    background: linear-gradient(36.64deg, #F249C2 0%, #C837DB 100%);
  }

  &:nth-child(9n) {
    background: linear-gradient(36.64deg, #99E02F 0%, #6EF740 100%);
  }

}

.process-model__process-body {
  display: none;
}

user-interaction-component {
  .frame {
    padding: 1rem 1.5rem;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15);
  }
}
